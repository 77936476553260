.stat-icon {
  width: 200px;
  height: 200px;
  padding: 10px;
}

.stat-plus {
  font-size: 1.6em;
}

@media screen and (max-width: 968px) {
  .stat-icon {
    width: 180px;
    height: 180px;
  }

  .stat-plus {
    font-size: 1.4em;
  }
}

@media screen and (max-width: 544px) {
  .stat-icon {
    width: 150px;
    height: 150px;
  }

  .stat-plus {
    font-size: 1.2em;
  }
}

@media screen and (max-width: 414px) {
  .stat-icon {
    width: 120px;
    height: 120px;
  }
}
