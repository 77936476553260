div.footer-row {
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
}

.main-footer {
  position: relative;
}

.footer-motto {
  flex-basis: 30%;
}

.footer-item {
  margin-bottom: 15px;
}

.copyright {
  text-align: center;
  position: absolute;
  bottom: 10px;
  width: 100%;
}

.footer-contact {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  flex-basis: 70%;
}

.footer-item-icon {
  width: 20px;
  height: 20px;
}

.footer-titles {
  font-size: 1.6em !important;
  font-weight: 600 !important;
  text-align: left !important;
}

.map-box {
  width: 330px;
  height: 200px;
}

.mission-text {
  font-style: italic;
}

@media screen and (max-width: 850px) {
  .footer-motto {
    flex-basis: 80%;
    margin: auto;
  }

  .footer-contact {
    margin: auto;
    flex-basis: 90%;
  }

  .mission-title {
    text-align: center !important;
  }

  .mission-text {
    text-align: center !important;
  }

  .footer-left {
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 700px) {
  .footer-motto {
    flex-basis: 80%;
    margin: auto;
  }

  .footer-contact {
    margin: auto;
    flex-basis: 80%;
  }

  .footer-titles {
    text-align: center !important;
  }

  .footer-center,
  .footer-right {
    flex-basis: 70%;
    margin-bottom: 30px;
  }

  .phones,
  .email {
    text-align: center;
  }
}

@media screen and (max-width: 500px) {
  .footer-motto {
    flex-basis: 90%;
  }
}

@media screen and (max-width: 415px) {
  .footer-motto {
    flex-basis: 99%;
  }

  .map-box {
    width: 290px;
    height: 200px;
    margin-bottom: auto;
  }
}
