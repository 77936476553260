#about-bg {
  background-image: url(../../images/pageTop/about/about-1400.jpg);
}

.about-nca-text-wrap {
  margin-top: 50px !important;
}

.we-unique-wrapper {
  width: 100%;
  margin: auto;
}

.about-us-unique {
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
  margin: auto;
}

.about-unique-item {
  flex-basis: 30%;
}

.unique-head-text {
  border-bottom: 1px solid #f35c27;
  padding-bottom: 4px;
}

.p-q-wrapper {
  margin-top: 50px !important;
}

.p-q-row {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.p-q-item {
  flex-basis: 45%;
  width: 45%;
}

.button-wrapper {
  width: 100%;
}

.p-q-button {
  padding: 4% 7% !important;
}

.partner-image-box {
  cursor: pointer;
}

@media screen and (max-width: 1200px) {
  #about-bg {
    background-image: url(../../images/pageTop/about/1280.jpg);
  }
}

@media screen and (max-width: 992px) {
  #about-bg {
    background-image: url(../../images/pageTop/about/about-992.jpg);
  }

  .about-unique-item {
    flex-basis: 32%;
  }

  .p-q-button {
    padding: 5% 8% !important;
  }

  .dutton-logo {
    height: 70%;
  }
}

@media screen and (max-width: 872px) {
  .about-us-unique {
    justify-content: space-around;
  }

  .about-unique-item {
    flex-basis: 40%;
  }
}

@media screen and (max-width: 820px) {
  .p-q-item {
    flex-basis: 49%;
    width: 49%;
  }
}
@media screen and (max-width: 768px) {
  #about-bg {
    background-image: url(../../images/pageTop/about/about-768.jpg);
  }

  .unique-title-underline {
    width: 40%;
  }

  .about-unique-item {
    flex-basis: 45%;
  }
}
@media screen and (max-width: 680px) {
  .about-unique-item {
    flex-basis: 45%;
  }

  .about-unique-item {
    flex-basis: 48%;
  }

  .dutton-logo {
    height: 80px;
  }
}

@media screen and (max-width: 544px) {
  #about-bg {
    background-image: url(../../images/pageTop/about/about-544.jpg);
  }

  .about-unique-item {
    flex-basis: 80%;
  }

  .p-q-item {
    flex-basis: 80%;
    width: 80%;
    margin: auto;
  }

  .unique-title-underline {
    width: 45%;
  }

  .unique-title-underline > div.underline-line {
    flex-basis: 40%;
  }
}

@media screen and (max-width: 414px) {
  #about-bg {
    background-image: url(../../images/pageTop/about/414.jpg);
  }

  .about-unique-item {
    flex-basis: 96%;
  }

  .dutton-logo {
    height: 60px;
  }
}

@media screen and (max-width: 360px) {
  .p-q-item {
    flex-basis: 90%;
    width: 90%;
    margin: auto;
  }
}
