#car-img-1 {
  background: url("../../images/landingpage/bg-car-1.jpg");
}

#car-img-2 {
  background: url("../../images/landingpage/bg-car-2.jpg");
}

#car-img-1.landing-item-wrapper,
#car-img-2.landing-item-wrapper {
  width: 100vw;
  height: 100vh;
  min-width: 100vw;
  max-height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.car-text {
  width: 100%;
  height: 100%;
  margin: auto;
}

.carousel-wrapper {
  position: relative;
}

.landing-list-item {
  line-height: 45px;
}

.carousel-indicators {
  bottom: 10%;
}

.carousel-indicators li {
  width: 150px;
  height: 7px;
  border-radius: 3px !important;
  background-color: #b14620;
}

.carousel-indicators li.active {
  background-color: #f35c27;
}

@media screen and (min-width: 1216px) {
}

@media screen and (max-width: 1030px) and (min-height: 800px) {
  #car-img-1.landing-item-wrapper,
  #car-img-2.landing-item-wrapper {
    height: 800px;
    max-height: 800px;
  }
}

@media screen and (max-width: 1216px) {
}

@media screen and (max-width: 992px) {
}

@media screen and (max-width: 768px) {
  #car-img-1 {
    background: url("../../images/landingpage/bg-car-1-768-700.jpg");
  }

  #car-img-2 {
    background: url("../../images/landingpage/bg-car-2-768-700.jpg");
  }

  #car-img-1.landing-item-wrapper,
  #car-img-2.landing-item-wrapper {
    height: 650px;
    max-height: 650px;
  }

  .landing-head-text {
    font-size: 2.5em;
  }

  .landing-list-item {
    font-size: 1.25em;
  }

  .car-2-check-list {
    padding: 0 0 0 15px;
  }

  .carousel-indicators {
    left: -30%;
  }
}

@media screen and (max-width: 544px) {
  #car-img-1 {
    background: url("../../images/landingpage/bg-car-1-544-500.jpg");
  }

  #car-img-2 {
    background: url("../../images/landingpage/bg-car-2-544-500.jpg");
  }

  #car-img-1.landing-item-wrapper,
  #car-img-2.landing-item-wrapper {
    height: 500px;
    max-height: 500px;
  }

  .landing-head-text {
    font-size: 1.5em;
  }

  .landing-list-item {
    line-height: 40px;
    font-size: 1em;
  }

  .carousel-indicators {
    bottom: 5%;
    left: -20%;
  }

  .carousel-indicators li {
    width: 75px;
    height: 5px;
  }
}

@media screen and (max-width: 414px) {
  #car-img-1 {
    background: url("../../images/landingpage/bg-car-1-414-380.jpg");
  }

  #car-img-2 {
    background: url("../../images/landingpage/bg-car-2-414-380.jpg");
  }

  #car-img-1.landing-item-wrapper,
  #car-img-2.landing-item-wrapper {
    height: 380px;
    max-height: 380px;
  }
}
@media screen and (max-width: 370px) {
  .carousel-indicators {
    bottom: 3%;
  }

  .landing-list-item {
    line-height: 30px;
    font-size: 15px;
  }
}

@media screen and (max-width: 320px) {
  #car-img-1 {
    background: url("../../images/landingpage/bg-car-1-320-280.jpg");
  }

  #car-img-2 {
    background: url("../../images/landingpage/bg-car-2-320-280.jpg");
  }

  #car-img-1.landing-item-wrapper,
  #car-img-2.landing-item-wrapper {
    height: 300px;
    max-height: 300px;
  }

  .landing-head-text {
    font-size: 1.25em;
  }
}
