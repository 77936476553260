.team-members-wrapper {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-around;
}

.team-member-card {
  flex-basis: 23%;
  white-space: 23%;
  box-shadow: 0px 6px 6px #00000022;
}

.team-member-underline {
  width: 18%;
}
.team-member-underline > div.underline-line {
  flex-basis: 38%;
}

@media screen and (max-width: 992px) {
  .team-member-underline {
    width: 20%;
  }
}

@media screen and (max-width: 769px) {
  .team-member-card {
    flex-basis: 43%;
    width: 43%;
    margin-bottom: 20px;
  }

  .team-member-underline {
    width: 30%;
  }

  .team-member-underline > div.underline-line {
    flex-basis: 38%;
  }
}

@media screen and (max-width: 544px) {
  .team-member-underline {
    width: 35%;
  }
}

@media screen and (max-width: 430px) {
  .team-member-card {
    flex-basis: 47%;
    width: 47%;
  }
}

@media screen and (max-width: 395px) {
  .team-member-card {
    flex-basis: 70%;
    width: 70%;
  }
}

@media screen and (max-width: 340px) {
  .team-member-card {
    flex-basis: 80%;
    width: 80%;
  }
}
