.icon {
  cursor: pointer;
}

.sm-img-icon {
  width: 16px;
  height: 16px;
}

.md-img-icon {
  width: 25px;
  height: 25px;
}
