.top-bar {
  width: 100vw;
  height: 350px;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.top-bar-page-title {
  width: 100%;
  text-align: center;
  position: absolute;
  bottom: 30%;
}

@media screen and (max-width: 1200px) {
  .top-bar {
    height: 275px;
  }

  .top-bar-page-title {
    bottom: 25%;
  }
}

@media screen and (max-width: 992px) {
  .top-bar {
    height: 250px;
  }
}

@media screen and (max-width: 768px) {
  .top-bar {
    height: 225px;
  }

  .top-bar-page-title {
    bottom: 20%;
    font-size: 2.25em !important;
  }
}

@media screen and (max-width: 544px) {
  .top-bar {
    height: 200px;
  }

  .top-bar-page-title {
    bottom: 17%;
    font-size: 2em !important;
  }
}

@media screen and (max-width: 414px) {
  .top-bar {
    height: 175px;
  }

  .top-bar-page-title {
    bottom: 17%;
    font-size: 1.75em !important;
  }
}
