.quote-inputs {
  border-radius: 0;
  font-size: 14px;
}

.quote-inputs:focus {
  box-shadow: none;
}

.quote-form-wrapper {
  padding: 15% 8% !important;
}

.quote-description {
  height: 200px !important;
}

@media screen and (min-width: 992px) {
  .quote-input-fields {
    padding: 5% !important;
  }
  .quote-inputs {
    font-size: 16px;
  }

  .quote-btn-home {
    width: 50% !important;
    display: inline-block;
    padding-top: 4%;
    padding-bottom: 4%;
  }

  .home-quote-underline {
    width: 70%;
  }
}

@media screen and (max-width: 992px) {
  .quote-description {
    height: 170px !important;
  }

  .home-quote-underline {
    width: 60%;
  }
}

@media screen and (max-width: 768px) {
  .quote-description {
    height: 170px !important;
  }

  .home-quote-underline {
    width: 60%;
  }

  .quote-form-wrapper {
    width: 85%;
    margin: auto;
  }

  .quote-form-wrapper {
    padding: 7% 5% !important;
  }
}

@media screen and (max-width: 544px) {
  .quote-btn-home {
    padding-left: 10%;
    padding-right: 10%;
  }

  .home-quote-underline {
    width: 60%;
  }

  .quote-form-wrapper {
    padding: 10% 7% !important;
  }
}

@media screen and (max-width: 414px) {
  .quote-form-wrapper {
    width: 95%;
    margin: auto;
  }
}
