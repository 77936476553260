.underline-row {
  display: flex;
  flex-wrap: nowrap;
  width: 50%;
  position: relative;
  margin: auto;
  align-items: center;
  justify-content: space-evenly;
}

.underline-line {
  flex-basis: 43%;
  height: 5px;
}

.underline-icon {
  width: 25px;
  height: 25px;
}

@media screen and (max-width: 768px) {
  .underline-row {
    width: 65%;
  }
}

@media screen and (max-width: 414px) {
  .underline-row {
    width: 75%;
  }
}
