.action-button {
  border: none;
  outline: none;
  color: #ffffffbb;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
  vertical-align: middle;
  box-shadow: none;
}

.action-button:hover {
  color: #ffffff;
  text-decoration: none;
}

.action-button:active {
  color: #ffffff;
  text-decoration: none !important;
  box-shadow: none !important;
  outline: none !important;
  border: none !important;
}

.action-button:disabled {
  cursor: not-allowed;
  opacity: 0.7;
}

.btn-lg-l {
  padding: 3% 7%;
}

.btn-md-m {
  padding: 2% 5%;
}

.btn-sm-s {
  padding: 1% 3%;
}

@media screen and (max-width: 544px) {
  .btn-md-m {
    padding: 3% 5%;
  }
}
