.mini-image {
  height: 100px;
  width: 100%;
  cursor: pointer;
}

.display-box,
.display-image {
  max-height: 450px;
  height: 420px;
  width: 100%;
  padding: 0 !important;
}

.project-image-wrapper {
  width: 70% !important;
  margin: auto;
}

.display-box {
  box-shadow: 1px 1px 1px #00000022;
}

.slick-arrow::before {
  color: #000000;
}

.city-carousel__left {
  left: 0;
}
.city-carousel__right {
  right: 0;
}

.selected-image {
  border: 3px solid #f35c27;
}

.project-details {
  background-color: #636b6f36;
}

.detail-key {
  font-weight: bold;
  color: #16181b6e;
}

.detail-value {
  color: #000000;
}

.bottom-border {
  height: 3px;
  width: 30%;
  margin-top: 10px;
  background-color: #f35c27;
}
@media screen and (max-width: 1150px) {
  .mini-image {
    height: 90px;
  }
}

@media screen and (max-width: 992px) {
  .project-image-wrapper {
    width: 75% !important;
    margin: auto;
  }

  .display-box,
  .display-image {
    max-height: 350px;
    height: 350px;
    width: 100%;
  }
}

@media screen and (max-width: 850px) {
  .project-image-wrapper {
    width: 80% !important;
    margin: auto;
  }

  .mini-image {
    height: 80px;
  }
}

@media screen and (max-width: 768px) {
  .mini-image {
    height: 75px;
  }
}

@media screen and (max-width: 650px) {
  .project-image-wrapper {
    width: 85% !important;
    margin: auto;
  }

  .mini-image {
    height: 70px;
  }
}

@media screen and (max-width: 500px) {
  .display-image,
  .display-box {
    max-height: 300px;
    height: 300px;
  }
}

@media screen and (max-width: 430px) {
  .display-image,
  .display-box {
    max-height: 270px;
    height: 270px;
  }

  .project-image-wrapper {
    width: 90% !important;
    margin: auto;
  }
}

@media screen and (max-width: 380px) {
  .display-image,
  .display-box {
    max-height: 250px;
    height: 250px;
  }

  .project-image-wrapper {
    width: 95% !important;
    margin: auto;
  }
}

@media screen and (max-width: 330px) {
  .project-image-wrapper {
    width: 100% !important;
    margin: auto;
  }
}
