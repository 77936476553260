.project-underline {
  width: 20%;
}

.project-container {
  margin-top: 50px !important;
}

.detail-item-wrapper {
  display: flex;
  padding: 10px 20px;
  border-bottom: 1px solid #686868;
}

.project-controls {
  display: flex;
  justify-content: space-between;
}

.project-control-arrow:hover,
.project-control-arrow:hover span {
  cursor: pointer;
  color: #e23b00 !important;
  font-weight: 600;
}

.project-control-arrow-right {
  align-self: flex-end;
}

.project-detail-item-left {
  width: 30%;
  flex-basis: 30%;
  padding-left: 20px;
}

.project-detail-item-right {
  width: 70%;
  flex-basis: 70%;
}

.project-underline > .underline-line {
  width: 40% !important;
  flex-basis: 40% !important;
}

.list-detail-item {
  border: none;
}

@media screen and (max-width: 1200px) {
  .project-underline {
    width: 30%;
  }
}

@media screen and (max-width: 992px) {
  .project-detail-item-left {
    padding-left: 15px;
  }
}

@media screen and (max-width: 768px) {
  .project-underline {
    width: 35%;
  }
}

@media screen and (max-width: 700px) {
  .project-detail-item-right {
    padding-left: 15px;
  }

  .project-detail-item-left {
    padding-left: 10px;
  }
}

@media screen and (max-width: 544px) {
}

@media screen and (max-width: 414px) {
  .detail-item-wrapper {
    flex-direction: column;
    padding: 15px 20px;
  }

  .project-detail-item-left,
  .project-detail-item-right {
    width: 100%;
    flex-basis: 100%;
    padding-left: 0;
  }

  .project-detail-item-right {
    padding-left: 0px;
  }

  .project-detail-item-left {
    padding-bottom: 5px;
  }
  .project-underline {
    width: 45%;
  }
}
