.main-logo {
  text-transform: uppercase;
  color: #ffffff;
  font-size: 2em;
  font-weight: 500;
  transition: all 400ms;
}

.main-logo:hover {
  text-decoration: none;
  color: #f35c27aa;
  font-weight: 600;
  transition: all 400ms;
}

@media screen and (max-width: 768px) {
  .main-logo {
    padding: 0 0 0 20p x;
  }
}

@media screen and (max-width: 544px) {
  .main-logo {
    font-size: 1.25em;
    padding: 0 10px;
  }
}
