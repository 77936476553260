.about-underline {
  width: 25%;
}

@media screen and (max-width: 768px) {
  .about-underline {
    width: 40%;
  }
}

@media screen and (max-width: 544px) {
  .home-about-pars {
    font-size: 14px;
  }

  .home-abt-title {
    margin-left: 0 !important;
  }
}

@media screen and (max-width: 414px) {
  .about-underline {
    width: 50%;
  }
}
