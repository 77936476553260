#portfolio-bg {
  background-image: url(../../images/pageTop/portfolio/1400.jpg);
}

@media screen and (max-width: 1280px) {
  #portfolio-bg {
    background-image: url(../../images/pageTop/portfolio/1280.jpg);
  }
}

@media screen and (max-width: 992px) {
  #portfolio-bg {
    background-image: url(../../images/pageTop/portfolio/992.jpg);
  }
}

@media screen and (max-width: 768px) {
  #portfolio-bg {
    background-image: url(../../images/pageTop/portfolio/768.jpg);
  }
}

@media screen and (max-width: 544px) {
  #portfolio-bg {
    background-image: url(../../images/pageTop/portfolio/544.jpg);
  }
}

@media screen and (max-width: 414px) {
  #portfolio-bg {
    background-image: url(../../images/pageTop/portfolio/414.jpg);
  }
}
