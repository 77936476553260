.nav-link {
  color: #ffffff88 !important;
  font-size: 1.1em !important;
  text-transform: capitalize !important;
}

.nav-link:hover {
  color: #b14620 !important;
}

.main-nav {
  display: block;
}

.nav-active {
  color: #ffffff !important;
}

.nav-item {
  list-style: none;
  padding: 4px 12px;
}

.nav-item-wrap {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: no-wrap;
  margin-bottom: 0;
}

.phone-nav,
.phone-nav-icon {
  display: none;
}

@media screen and (max-width: 992px) {
  .nav-item {
    padding: 4px 4px;
  }
}

@media screen and (max-width: 872px) {
  .nav-item-wrap {
    flex-direction: column;
    justify-content: flex-start;
    margin-bottom: 0;
    width: 80%;
    margin: auto;
  }

  .navbar-wrapper {
    display: relative;
  }

  .main-nav {
    display: none;
    position: fixed;
    top: 73px;
    right: 0;
    height: 70vh;
    z-index: 100;
    background-color: #06111f;
    width: 60vw;
    padding-top: 50px;
  }

  .phone-nav-icon {
    display: block;
    position: absolute;
    right: 50px;
    top: 20px;
    font-size: 2em;
    z-index: 110;
  }

  /* when nav is active */
  .phone-nav-icon-hide {
    top: 250px;
    right: 62vw;
  }
}

@media screen and (max-width: 768px) {
  .phone-nav-icon {
    display: block;
    position: absolute;
    right: 30px;
    top: 15px;
  }
}
@media screen and (max-width: 544px) {
  .main-nav {
    top: 56px;
    width: 80vw;
  }

  .phone-nav-icon {
    display: block;
    position: absolute;
    right: 30px;
    top: 10px;
    font-size: 1.25em;
  }

  /* when nav is active */
  .phone-nav-icon-hide {
    top: 250px;
    right: 82vw;
  }
}

@media screen and (max-width: 414px) {
  .main-nav {
    top: 50.5px;
    width: 80vw;
  }

  .phone-nav-icon {
    display: block;
    position: absolute;
    right: 30px;
    top: 10px;
    font-size: 1.25em;
  }

  /* when nav is active */
  .phone-nav-icon-hide {
    top: 250px;
    right: 82vw;
  }
}
