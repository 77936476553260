.testimonials-wrapper {
  width: 100%;
  height: 100%;
}

.testimonial-content-wrapper {
  display: flex;
  width: 80%;
  margin: auto;
  min-height: 200px;
  justify-content: space-evenly;
}

.testimonial-client-image {
  flex-basis: 20%;
}

.testimonial-image {
  width: 165px;
  height: 165px;
  border-radius: 50%;
}

.testimonial-text-client {
  flex-basis: 80%;
}

.carousel-control-next,
.carousel-control-prev {
  width: 8%;
  top: -20%;
  opacity: 1;
}

.carousel-control-next:hover,
.carousel-control-prev:hover {
  opacity: 0.7;
}

.testimonials-title-underline {
  width: 40%;
}

@media screen and (max-width: 1100px) {
  .testimonial-client-image {
    flex-basis: 25%;
  }

  .testimonial-text-client {
    flex-basis: 75%;
  }
}

@media screen and (max-width: 872px) {
  .testimonial-client-image {
    flex-basis: 25%;
  }

  .testimonial-text-client {
    flex-basis: 73%;
  }

  .testimonial-image {
    width: 150px;
    height: 150px;
  }
}

@media screen and (max-width: 768px) {
  .testimonial-client-image {
    width: 100%;
    margin: auto;
    text-align: center;
    margin-bottom: 10px;
  }

  .testimonial-text-client {
    width: 80%;
    margin: auto;
  }

  .testimonial-content-wrapper {
    flex-direction: column;
  }
}

@media screen and (max-width: 544px) {
  .testimonial-image {
    width: 120px;
    height: 120px;
  }

  .testimonial-text-client {
    width: 90%;
    text-align: center;
  }

  .testimonials-title-underline {
    width: 60%;
  }

  .testimonials-title-underline > div.underline-line {
    flex-basis: 40%;
  }
}

@media screen and (max-width: 768px) {
  .testimonial-image {
    width: 120px;
    height: 120px;
  }

  .testimonial-text-client {
    width: 90%;
  }

  .carousel-control-next,
  .carousel-control-prev {
    top: -10%;
  }
}
