#services-bg {
  background-image: url(../../images/pageTop/services/services-lg.jpg);
}

.service-list-container {
  margin-top: 10px !important;
}

.services-into-text {
  margin-bottom: 40px !important;
}

.services-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
}

.service-item {
  flex-basis: 32%;
  width: 32%;
  margin-bottom: 20px;
}

.service-top {
  width: 100%;
}

@media screen and (max-width: 1280px) {
  #services-bg {
    background-image: url(../../images/pageTop/services/services-lg-1280.jpg);
  }
}

@media screen and (max-width: 992px) {
  #services-bg {
    background-image: url(../../images/pageTop/services/services-md-992.jpg);
  }

  .service-item {
    flex-basis: 45%;
    width: 45%;
  }
}

@media screen and (max-width: 768px) {
  #services-bg {
    background-image: url(../../images/pageTop/services/services-md-768.jpg);
  }
}

@media screen and (max-width: 544px) {
  #services-bg {
    background-image: url(../../images/pageTop/services/services-md-544.jpg);
  }

  .service-item {
    flex-basis: 70%;
    width: 70%;
    margin: 20px auto;
  }

  .service-icon {
    width: 120px;
    height: 120px;
  }
}

@media screen and (max-width: 414px) {
  #services-bg {
    background-image: url(../../images/pageTop/services/services-md-414.jpg);
  }

  .service-item {
    flex-basis: 95%;
    width: 95%;
  }

  .service-icon {
    width: 100px;
    height: 100px;
  }
}
