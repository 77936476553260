.header-row {
  display: flex;
  justify-content: space-between;
  padding: 15px 0 10px;
  flex-wrap: nowrap;
  height: 100%;
  align-content: center;
}

.main-header {
  transition: all 600ms ease-in-out;
}

@media screen and (max-width: 414px) {
  .header-row {
    padding: 10px 0;
  }
}
