@import url("https://fonts.googleapis.com/css?family=Raleway:400,500,600,700,800,900&display=swap");

body,
* {
  font-family: "Raleway", sans-serif;
  font-weight: 400;
  color: #06111f;
}

body,
#root {
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
}

.techy-blue {
  color: #04a7e7;
}

.text-thin {
  font-weight: 300;
}

/* medium bold */
.text-sm-bold {
  font-weight: 500;
}

/*  Semi Bold */
.text-md-bold {
  font-weight: 600;
}

/* Bold */
.text-bold {
  font-weight: 700;
}

/* Bolder */
.text-x-bold {
  font-weight: 800;
}

.txt-orange {
  color: #f35c27 !important;
}

.sm-txt {
  font-size: 14px;
}

.md-txt {
  font-size: 1.25em;
}

.md-md-txt {
  font-size: 1.5em;
}

.lg-txt {
  font-size: 1.875em;
}

.xl-txt,
.default-title {
  font-size: 2.5em;
}

.bg-blur {
  position: fixed;
  display: none;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #06111f66;
  z-index: 20;
  width: 100vw;
  height: 100vh;
}

.sxl-txt {
  font-size: 3em;
}

.bg-navy {
  background-color: #06111f;
}

.bg-orange {
  background-color: #f35c27;
}

.bg-orange-faded {
  background-color: #b14620;
}

.default-title {
  width: 100%;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 5px;
  margin: 0;
}

.gen-wrapper {
  margin: 100px 0;
  padding: 3% 5%;
  height: 100%;
}

.my-container {
  width: 80%;
  margin: auto;
  height: 100%;
}

ul,
li {
  list-style: none;
}

.large-text-center {
  padding: 25% !important;
}

.default-y-spacing {
  margin-top: 100px;
  margin-bottom: 100px;
  position: relative;
}

.text-white-dull {
  color: #ffffffaa !important;
}

.grecaptcha-badge {
  visibility: collapse !important;
}

@media screen and (min-width: 1216px) {
}

@media screen and (max-width: 1216px) {
}

@media screen and (max-width: 992px) {
  .md-txt {
    font-size: 1.15em;
  }

  .md-md-txt {
    font-size: 1.35em;
  }

  .lg-txt {
    font-size: 1.575em;
  }

  .default-title {
    font-size: 2.2em;
  }
}

@media screen and (max-width: 768px) {
  .my-container {
    width: 90%;
  }

  .large-text-center {
    padding-left: 8% !important;
  }

  .default-title {
    font-size: 2em;
  }

  .col-md-6 {
    width: 100% !important;
  }

  .default-y-spacing {
    margin-top: 75px;
    margin-bottom: 75px;
  }
}

iframe[frameborder="0"][scrolling="no"] {
  position: fixed !important;
  top: 100px !important;
  margin: auto !important;
}

@media screen and (max-width: 544px) {
  .large-text-center {
    padding: 30% 10% !important;
  }

  .txt-sm-sm {
    font-size: 13px;
  }

  .default-title {
    font-size: 1.5em;
  }

  .default-y-spacing {
    margin-top: 50px;
    margin-bottom: 50px;
  }
}
@media screen and (max-width: 414px) {
  .default-title {
    font-size: 1.25em;
    width: 95%;
    margin: auto;
  }
}

@media screen and (max-width: 320px) {
}
