#contact-bg {
  background-image: url(../../images/pageTop/contact/1400.jpg);
}

.contact-form {
  width: 900px;
  margin: auto;
}

@media screen and (max-width: 1280px) {
  #contact-bg {
    background-image: url(../../images/pageTop/contact/1280.jpg);
  }
}

@media screen and (max-width: 992px) {
  #contact-bg {
    background-image: url(../../images/pageTop/contact/992.jpg);
  }

  .contact-title {
    font-size: 1.6em !important;
  }

  .contact-form {
    width: 700px;
  }
}

@media screen and (max-width: 768px) {
  #contact-bg {
    background-image: url(../../images/pageTop/contact/768.jpg);
  }

  .contact-title {
    font-size: 1.4em !important;
  }

  .contact-form {
    width: 85%;
  }
}

@media screen and (max-width: 544px) {
  #contact-bg {
    background-image: url(../../images/pageTop/contact/544.jpg);
  }

  .contact-title {
    font-size: 1.2em !important;
    width: 92%;
    margin: auto;
  }

  .contact-form {
    width: 92%;
  }
}

@media screen and (max-width: 414px) {
  #contact-bg {
    background-image: url(../../images/pageTop/contact/414.jpg);
  }

  .contact-form {
    width: 95%;
  }
}
